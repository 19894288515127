<template>
  <div v-if="isshowgrid">
    <va-card title="Account List">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input class="va-input-search-container"
            :value="term"
            placeholder="search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class="flex xs12 md3 offset--md3">
          <va-button color="success" icon="fa fa-plus" @click="add()">Add</va-button>
        </div>
      </div>
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        clickable
      >
        <template slot="actions" slot-scope="props">
          <va-button flat small color="gray" icon="fa fa-pencil" @click="edit(props.rowData)" class="ma-0"></va-button>
        </template>
      </va-data-table>
    </va-card>
  </div>
  <div v-else-if='isshowForm'>
    <div>
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <span class="va-form-label va-form-required-label">Account Name</span>
            <va-input
              v-model.trim="account_name"
              v-if="isCreate" 
              type="text"              
              placeholder="Enter Account Name"
              :error="!!playaccountError.length"
              :error-messages="playaccountError"
            />
            <va-input
              v-if="isUpdate"
              v-model="account_name"
              type="text"
              disabled
              placeholder="Enter Account Name"
            />
            <div class="flex xs12">
                <span class="va-form-label va-form-required-label">Key File</span>
                <va-file-upload
                  type="single"
                  dropzone
                  v-model="logo_file"
                />
            </div>
            <div class="d-flex justify--end mt-3">
              <va-button type="submit" class="my-0 va-button-normal-cancel" @click.prevent="list()">Cancel</va-button>
              <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="CreatePlayAccount()">Create</va-button>
              <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updatePlayAccount()">Update</va-button>              
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import { debounce } from 'lodash'
Vue.use(vueResource)
export default {
  name: 'playstoreaccount',
  created () {
    this.getAllAccounts()
   //  const loader = Vue.$loading.show({ width: 40, height: 40 })
	  // this.$http.get(config.menu.host + '/playaccounts').then(response => {
	  //     loader.hide()
	  //     this.playaccountData = response.body
	  //     this.isshowForm = false
	  //     this.isshowgrid = true
   //      this.getAllAccounts()
	  // }, error => {
	  //     loader.hide()
	  //     if (error && error.body) {
	  //       Vue.notify({ text: error.body, type: 'error' })
	  //     }
	  // })
  },
  data () {
    return {
      term: null,
      perPage: '10',
      perPageOptions: ['4', '6', '10', '20'],
      isshowgrid: false,
      isshowForm: false,
      isCreate: false,
      isUpdate: false,
      showRemoveModal: false,
      account_name: '',
      logo_file: [],
      playaccountData: [],
      playaccountError: [],
    }
  },
  computed: {
    formReady () {
      return !this.playaccountError.length
    },
    Check_upload () {
      if (this.logo_file[0]) {
        return true
      } else {
        Vue.notify({ text: 'please Upload key file', type: 'error' })
        return false
      }
    },
    fields () {
      return [{
        name: 'sno',
        title: 'S.NO',
        width: '30%',
      },
      {
        name: '__slot:marker',
        width: '30px',
        dataClass: 'text-center',
      },
      {
        name: 'account_name',
        title: 'Account Name',
        width: '40%',
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
        width: '30%',
      }]
    },
    filteredData () {
      return search(this.term, this.playaccountData)
    },
  },
  methods: {
	    getAllAccounts () {
	      this.$http.get(config.menu.host + '/playaccounts').then(response => {
          let index = 0
          this.playaccountData = response.body.map(function (item) {
              item.id = index++
              item.sno = index
              item.is_existing = true
              item.checkbox_value = false
              return item
          })
          this.isshowForm = false
	        this.isshowgrid = true
	      })
	    },
    CreatePlayAccount () {
      this.playaccountError = this.account_name ? [] : ['Account Name is required']
      if (!this.formReady) {
        return
      }
      if (!this.Check_upload) {
        return
      }
      
      var payload = new FormData()
      payload.append('key', this.logo_file[0])    
      payload.append('account_name', this.account_name)
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.post(config.menu.host + '/playaccounts', payload).then(responseData => {
        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
    updatePlayAccount () {
      if (!this.Check_upload) {
        return
      }
      var payload = new FormData()
      payload.append('key', this.logo_file[0])
      payload.append('account_name', this.account_name)
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + '/playaccounts', payload).then(responseData => {
        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
        }
        this.list()
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
        }
      })
    },
	  add () {  
	    this.title = 'Create Account';
      this.account_name = ''
      this.playaccountError = []
      this.logo_file = []
      this.isshowForm = true
      this.isshowgrid = false
      this.isUpdate = false
      this.isCreate = true
	    },
    edit (row) {
      this.title = 'Update Account';
      this.account_name = row.account_name;
      this.logo_file = []
      this.isshowgrid = false
      this.isshowForm = true
      this.isCreate = false
      this.isUpdate = true
    },
	list () {
      this.isshowForm = false
      this.isshowgrid = true
      this.getAllAccounts()
    },
	    search: debounce(function (term) {
	    	this.term = term
	    }, 400),
  },
}
</script>
